import CanonicalLinkTag from "components/canonicalLinkTag/canonicalLinkTag";
import LanguageHeadTagsDynamic from "components/localization/languageHeadTagsDynamic/languageHeadTagsDynamic";
import PageRenderer from "components/page/pageRenderer";
import usePageClassNames from "hooks/usePageClassNames";
import usePublishedIndicator from "hooks/usePublishedIndicator";
import useStartEditMode from "hooks/useStartEditMode";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useSelector } from "react-redux";
import { contentElementSettings } from "services/contentElementSettings/contentElementSettingsService";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import {
  getCmsDefaultPage,
  getDefaultPage,
} from "services/page/pageServerService";
import {
  getServerSideTranslationNamespaces,
  isCmsAccessTokenValid,
  isNonDesktopDevice,
  serverSideTranslationsCustomLanguage,
} from "utils/serverUtil";
import {
  buildMetaTags,
  getDocumentTitle,
  getMetaTitleOrNull,
  removeUrlQueryParams,
} from "utils/util";

// NOTE: import contentmanager only components dynamic
const DraftPageRenderer = dynamic(() =>
  import("components/page/draftpageRenderer")
);

export default function Home(props) {
  const editDraftPage = useSelector(
    (state) => state.cms.editInfo.editDraftPage
  );
  const availableElements = useSelector(
    (state) => state.cms.editInfo.availableElements
  );
  removeUrlQueryParams();
  useStartEditMode(props.startEditMode);
  const { t: tPublic } = useTranslation("public");
  usePublishedIndicator(props.page.isFallback ? false : props.page.published);

  const pageClassNames = usePageClassNames(props.page);

  // google search variables
  const gsEnabled = globalSettings.googleSearch?.googleSearchEnabled;
  const gsId = globalSettings.googleSearch?.googleSearchId;

  return (
    <>
      <Head>
        <title>
          {props.pageTitle
            ? props.pageTitle
            : `${getDocumentTitle()} - ${tPublic("homepage")}`}
        </title>
        {props.page.seoSettings && props.page.seoSettings.length > 0
          ? buildMetaTags(props.page.seoSettings)
          : null}
        {gsEnabled && gsId && (
          <meta name="google-site-verification" content={gsId} />
        )}
      </Head>
      {props.page && props.page.canonicalLinkUrl ? (
        <CanonicalLinkTag href={`${props.page.canonicalLinkUrl}`} />
      ) : null}
      <LanguageHeadTagsDynamic
        locale={props.page ? props.page.locale : null}
        urlPrefix={""}
        url={props.page ? "" : null}
        localizations={props.page ? props.page.localizations : null}
        customCanonicalTag={props.page ? props.page.canonicalLinkUrl : null}
      />
      {/* No BreadCrumbs */}
      <div className={`page content ${pageClassNames}`}>
        {editDraftPage ? (
          <DraftPageRenderer
            draftPage={editDraftPage}
            availableElements={availableElements}
            contentElementSettings={contentElementSettings}
            isMobile={props.isMobile}
          />
        ) : (
          <PageRenderer
            data={props.page}
            contentElementSettings={contentElementSettings}
            showCmsPageWarnings={props.showCmsPageWarnings}
            isMobile={props.isMobile}
          />
        )}
      </div>
      <style jsx>
        {`
          .content {
            max-width: ${globalSettings?.layout?.containerwidth || "100%"};
            margin-left: auto;
            margin-right: auto;
          }
        `}
      </style>
    </>
  );
}

export async function getServerSideProps(context) {
  const { req, query, locale } = context;

  const isAuthenticatedCmsUser = isCmsAccessTokenValid(req);

  let result;
  let startEditMode = false;
  let showCmsPageWarnings = false;
  if (isAuthenticatedCmsUser) {
    // CmsUser (not-only published pages)
    result = await getCmsDefaultPage(req, locale);
    if (query.edit === "true") {
      startEditMode = true;
    }
    showCmsPageWarnings = true;
  } else {
    // nonCmsUser (only published pages)
    result = await getDefaultPage(locale);
  }

  if (result.success) {
    const isMobile = isNonDesktopDevice(context.req.headers["user-agent"]);
    const pageData = result.response.data;

    return {
      props: {
        ...(await serverSideTranslationsCustomLanguage(
          locale,
          getServerSideTranslationNamespaces(isAuthenticatedCmsUser),
          serverSideTranslations
        )),
        page: pageData,
        pageTitle: getMetaTitleOrNull(pageData),
        startEditMode: startEditMode,
        showCmsPageWarnings: showCmsPageWarnings,
        isMobile: isMobile,
      },
    };
  } else {
    return {
      notFound: true,
    };
  }
}
