import Head from "next/head";

/**
 * CanonicalLinkTag
 * Prevents jsx-class from being added to link-Tag.
 * @param {String} props.href the canonicalURL
 * @returns
 */
const CanonicalLinkTag = (props) => {
  return (
    <>
      <Head>
        <link rel="canonical" href={props.href} />
      </Head>
    </>
  );
};

export default CanonicalLinkTag;
